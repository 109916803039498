import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Blog from 'components/blog/Blog';

function BlogPage(props) {
  return <Blog {...props} />;
}

export default function CodingBlogWithData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          blog: allWordpressPost(
            filter: { status: { eq: "publish" } }
            sort: { fields: date, order: ASC }
          ) {
            nodes {
              id
              title
              date
              slug
              excerpt
              categories {
                name
              }
              featured_media {
                alt_text
                source_url
                title
              }
            }
          }
        }
      `}
      render={(data) => <BlogPage data={data} {...props} />}
    />
  );
}
